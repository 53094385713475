<template>
  <div>
    <div class="row">
      <div class="flex xs12">
        <va-card
          class="no-h-padding"
          :title="$t('trainings.trainings.new')"
        >
          <actions
            slot="actions"
            crud-links="trainings"
            controller="DcpiTrainings"
            :actions="actions"
          />
          <form-wizard
            title=""
            subtitle=""
            :color="$themes.primary"
            :next-button-text="$t('layout.wizard.next')"
            :back-button-text="$t('layout.wizard.back')"
            :finish-button-text="$t('layout.wizard.finish')"
            @on-change="changeTab"
            @on-complete="prepare"
          >
            <tab-content
              :title="$t('trainings.trainings.wizard.data')"
              :before-change="isTrainingValid"
            >
              <div class="text-center">
                <p><strong>{{ $t('trainings.trainings.wizard.form.data') }}</strong></p>
              </div>
              <data-form
                ref="trainingForm"
                :training="training"
                :wizard="true"
                :loading="loading"
                @submit="saveForm"
                @selected-country="propagateCountry"
              />
            </tab-content>
            <tab-content
              :title="$t('trainings.trainings.wizard.public_details')"
              :before-change="isPublicDetailsValid"
            >
              <div class="text-center">
                <p><strong>{{ $t('trainings.trainings.wizard.form.public_details') }}</strong></p>
              </div>
              <training-public-details
                ref="trainingPublicDetails"
                :training="training"
                :loading="loading"
                :wizard="true"
                @submit="savePublicDetails"
              />
            </tab-content>
            <tab-content
              :title="$t('trainings.trainings.wizard.trainers')"
              :before-change="isTrainersValid"
            >
              <div class="text-center">
                <p><strong>{{ $t('trainings.trainings.wizard.form.trainers') }}</strong></p>
              </div>
              <trainers-table
                ref="trainersTable"
                :training="training"
                :loading="loading"
                :wizard="true"
                :edit="true"
                @submit="saveTrainers"
              />
            </tab-content>
            <tab-content
              :title="$t('trainings.trainings.wizard.participants')"
              :before-change="isParticipantsValid"
            >
              <div class="text-center">
                <p><strong>{{ $t('trainings.trainings.wizard.form.participants') }}</strong></p>
              </div>
              <participants-table
                ref="participantsTable"
                :country="selectedCountry"
                :training="training"
                :loading="loading"
                :wizard="true"
                :edit="true"
                @submit="saveParticipants"
              />
            </tab-content>
            <tab-content
              :title="$t('trainings.trainings.wizard.photos')"
              :before-change="isPhotosValid"
            >
              <div class="text-center">
                <p><strong>{{ $t('trainings.trainings.wizard.form.photos') }}</strong></p>
              </div>
              <photos-upload
                ref="photosUpload"
                :wizard="true"
                @submit="savePhotos"
              />
            </tab-content>
            <tab-content
              :title="$t('trainings.trainings.tabs.documents.current')"
              :before-change="isMaterialValid"
            >
              <div class="text-center">
                <p><strong>{{ $t('trainings.trainings.tabs.documents.current') }}</strong></p>
              </div>
              <materials-upload
                ref="materialsUpload"
                :wizard="true"
                @submit="saveDocuments"
              />
            </tab-content>
            <tab-content :title="$t('layout.confirm')">
              <transition-group
                name="fade"
                mode="out-in"
              >
                <div
                  key="review"
                  v-show="!loading && !submitError"
                >
                  <div class="text-center">
                    <h4>{{ $t('layout.confirm') }}</h4>
                    <p>{{ $t('layout.wizard.verify')}}</p>
                  </div>
                  <hr>
                  <view-data
                    ref="trainingView"
                    class="container-fluid"
                    :local="true"
                  />
                </div>
                <div
                  key="completed"
                  v-show="loading"
                >
                  <div class="text-center">
                    <h4>{{ $t('layout.completed') }}</h4>
                    <p>{{ $t('layout.wizard.sending')}}</p>
                    <va-progress-bar :value="progressValue" />
                  </div>
                </div>
                <div
                  key="error"
                  v-show="submitError"
                >
                  <div class="text-center">
                    <h4>{{ $t('layout.submit_error') }}</h4>
                    <p>{{$t('layout.wizard.error') }}</p>
                    <va-button
                      type="button"
                      color="secondary"
                      @click="retry"
                    >
                      {{ $t('layout.retry') }}
                    </va-button>
                  </div>
                </div>
              </transition-group>
            </tab-content>
          </form-wizard>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

const DataForm = () => import(/* webpackPrefetch: true */ './Form')
const ViewData = () => import(/* webpackPrefetch: true */ './View')
const TrainersTable = () => import(/* webpackPrefetch: true */ './Trainers')
const TrainingPublicDetails = () => import(/* webpackPrefetch: true */ './PublicDetails')
const ParticipantsTable = () => import(/* webpackPrefetch: true */ './Participants')
const PhotosUpload = () => import(/* webpackPrefetch: true */ './Photos')
const materialsUpload = () => import(/* webpackPrefetch: true */ './Documents')
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')

export default {
  name: 'trainings-new',
  components: {
    Actions,
    DataForm,
    ViewData,
    FormWizard,
    TabContent,
    PhotosUpload,
    TrainersTable,
    ParticipantsTable,
    TrainingPublicDetails,
    materialsUpload,
  },
  data () {
    return {
      actions: ['index'],
      loading: false,
      submitError: false,
      selectedCountry: null,
      progressValue: 0,
      training: {
        id: 0,
      },
      publicData: {},
      fullTraining: {},
      localTraining: {},
      photos: [],
      material: [],
      trainers: [],
      participants: [],
    }
  },
  methods: {
    async changeTab (prevIndex, nextIndex) {
      await this.$nextTick()
      window.dispatchEvent(new Event('resize'))
    },
    // FIXME
    routeBuilder () {
      return 'trainings/dcpi/'
    },
    trainingChange () {
      this.$refs.trainingForm.submit()
    },
    isTrainingValid () {
      if (this.$refs.trainingForm.notValid) return false

      this.trainingChange()
      return true
    },
    publicDetailsChange () {
      this.$refs.trainingPublicDetails.submit()
    },
    isPublicDetailsValid () {
      this.publicDetailsChange()
      return true
    },
    trainersChange () {
      this.$refs.trainersTable.submit()
    },
    isTrainersValid () {
      if (this.$refs.trainersTable.notValid) return false

      this.trainersChange()
      return true
    },
    participantsChange () {
      this.$refs.participantsTable.submit()
    },
    isParticipantsValid () {
      if (this.training.end_date && this.$refs.participantsTable.notValid) {
        this.showToast(this.$t('trainings.trainings.wizard.modal.end_date_set_toast'), {
          icon: 'fa-times',
          position: 'top-right',
        })
        return false
      }

      this.participantsChange()
      return true
    },
    photosChange () {
      this.$refs.photosUpload.submit()
      this.prepareLocal()
    },
    isPhotosValid () {
      if (this.$refs.photosUpload.notValid) return false

      this.photosChange()
      return true
    },
    materialChange () {
      this.$refs.materialsUpload.submit()
      this.prepareLocal()
    },
    isMaterialValid () {
      if (this.$refs.photosUpload.notValid) return false

      this.materialChange()
      return true
    },
    saveForm (training) {
      // console.log('training', training)
      this.training = training
    },
    saveTrainers (trainers) {
      // console.log('trainers', trainers)
      this.trainers = trainers
    },
    saveParticipants (participants) {
      // console.log('participants', participants)
      this.participants = participants
    },
    savePhotos (photos) {
      // console.log('photos', photos)
      this.photos = photos
    },
    saveDocuments (material) {
      // console.log('material', material)
      this.material = material
    },
    savePublicDetails (data) {
      this.publicData = Object.assign({}, data)
    },
    propagateCountry (country) {
      this.selectedCountry = country
    },
    async readFile (f) {
      return new Promise((resolve, reject) => {
        if (!f.type.match('image.*')) {
          reject(new Error('no image'))
        }

        const reader = new FileReader()
        reader.onload = (function (theFile) {
          return function (e) {
            const file = {
              id: Math.floor(Math.random() * 10),
              name: theFile.name,
              path: e.target.result,
            }
            resolve(file)
          }
        })(f)

        // Read in the image file as a data URL.
        reader.readAsDataURL(f)
      })
    },
    async readFileD (f) {
      console.log(f)
      return new Promise((resolve, reject) => {
        if (!f.type.match('.pdf')) {
          reject(new Error('no pdf'))
        }

        const reader = new FileReader()
        reader.onload = (function (theFile) {
          return function (e) {
            const file = {
              id: Math.floor(Math.random() * 10),
              path_ma: theFile.name,
              path: e.target.result,
            }
            resolve(file)
          }
        })(f)

        // Read in the image file as a data URL.
        reader.readAsDataURL(f)
      })
    },
    async prepareLocalPhotos (files) {
      const p = []

      for (const f of files) {
        let photo = null
        try {
          photo = await this.readFile(f)
        } catch (e) {
          continue
        }
        p.push(photo)
      }
      return p
    },
    async prepareLocalMaterial (files) {
      const p = []

      for (const f of files) {
        let material = null
        try {
          material = await this.readFileD(f)
        } catch (e) {
          continue
        }
        p.push(material)
      }
      return p
    },
    async prepareLocal () {
      const training = Object.assign({}, this.training)
      const organizer = Object.assign({}, this.$refs.trainingForm.selectedOrganizer)
      const district = Object.assign({}, this.$refs.trainingForm.selectedDistrict)
      const country = Object.assign({}, this.$refs.trainingForm.selectedCountry)
      const region = Object.assign({}, this.$refs.trainingForm.selectedRegion)

      country.region = Object.assign({}, region)
      district.country = Object.assign({}, country)
      training.district = Object.assign({}, district)
      training.organizer = Object.assign({}, organizer)

      const trainers = this.$refs.trainersTable.trainersList.slice(0)
      const participants = this.$refs.participantsTable.participantsList.slice(0)

      training.trainers = trainers.slice(0)
      training.participants = participants.slice(0)

      if (this.photos.length > 0) {
        const photos = await this.prepareLocalPhotos(this.photos)
        training.photos = photos.slice(0)
      }

      this.$refs.trainingView.training = Object.assign({}, training)
      if (training.photos && training.photos.length > 0) {
        this.$refs.trainingView.setImages()
      }

      if (this.material.length > 0) {
        const material = await this.prepareLocalMaterial(this.material)
        training.material = material.slice(0)
      }

      this.$refs.trainingView.training = Object.assign({}, training)
      if (training.material && training.material.length > 0) {
        this.$refs.trainingView.setMaterial()
      }
    },
    async prepare () {
      if (this.training.end_date) {
        const confirmed = await this.confirmSend()
        if (!confirmed) return
      }

      const training = Object.assign({}, this.training)
      training.trainers = this.trainers.users.to_add
      training.participants = this.participants.users.to_add

      if (this.publicData.title) {
        training.title = this.publicData.title
      }
      if (this.publicData.description) {
        training.description = this.publicData.description
      }

      this.fullTraining = Object.assign({}, training)
      this.submit()
    },
    async submit () {
      if (this.loading) return
      this.loading = true

      let response = false
      try {
        response = await this.$http.post(this.routeBuilder(), this.fullTraining)
      } catch (error) {
        // console.log('Error updating data', error)
        this.loading = false
        return
      }

      this.fullTraining.id = response.data.data.id
      let success = await this.submitPhotos()
      // let successs = await this.submitDocuments()
      if (this.publicData.image_path) {
        const successPublic = await this.submitPublicDetails()
        success = success && successPublic
        // successs = successs && successPublic
      }

      if (success) {
        this.$router.push({ name: 'trainingsIndex' })
      }
    },
    async submitPublicDetails () {
      this.loading = true
      this.submitError = false
      const trainingId = this.fullTraining.id

      const training = new FormData()
      training.append('id', trainingId)
      training.append('_method', 'PUT')
      training.append('image_path', this.publicData.image_path)

      try {
        await this.$http.post('trainings/dcpi/' + trainingId, training, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      } catch (error) {
        // console.log('Error uploading photo', error)
        this.loading = false
        this.submitError = true
        return false
      }

      return true
    },
    async submitPhotos () {
      this.loading = true
      this.submitError = false
      const trainingId = this.fullTraining.id
      const photosTotal = this.photos.length

      for (let i = 1; i <= photosTotal; i++) {
        const training = new FormData()
        training.append('id', trainingId)
        training.append('dcpi_photos[]', this.photos[0])
        training.append('_method', 'PUT')

        try {
          await this.$http.post('trainings/dcpi/' + trainingId, training, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
        } catch (error) {
          // console.log('Error uploading photo', error)
          this.loading = false
          this.submitError = true
          break
        }
        this.photos.shift()
        this.progressValue = (i / photosTotal) * 100
      }
      if (this.submitError) return false

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })

      return true
    },
    async submitDocuments () {
      this.loading = true
      this.submitError = false
      const trainingId = this.fullTraining.id
      const materialTotal = this.material.length

      for (let i = 1; i <= materialTotal; i++) {
        const training = new FormData()
        training.append('id', trainingId)
        training.append('dcpi_material[]', this.material[0])
        training.append('_method', 'PUT')

        try {
          console.log(trainingId)
          console.log(training)
          await this.$http.post('trainings/dcpi/' + trainingId, training, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
        } catch (error) {
          // console.log('Error uploading photo', error)
          this.loading = false
          this.submitError = true
          break
        }
        this.material.shift()
        this.progressValue = (i / materialTotal) * 100
      }
      if (this.submitError) return false

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })

      return true
    },
    retry () {
      if (this.fullTraining.id !== 0) return this.submitPhotos()
      this.submit()
    },
    async confirmSend () {
      const dontShow = localStorage.getItem('dont_show_confirm_training')
      if (dontShow === 'true') return true

      const dialog = await this.$swal.fire({
        icon: 'warning',
        title: this.$t('trainings.trainings.wizard.modal.end_date_set_title'),
        text: this.$t('trainings.trainings.wizard.modal.end_date_set_text'),
        input: 'checkbox',
        inputValue: 0,
        inputPlaceholder: this.$t('layout.form.dont_show_again'),
        confirmButtonText: this.$t('layout.wizard.continue'),
      })

      if (dialog.isConfirmed) {
        localStorage.setItem('dont_show_confirm_training', 'true')
      }

      return dialog.isConfirmed
    },
  },
}
</script>
